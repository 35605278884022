<template>
  <div id="grid_table">
    <div v-if="itemsData.length > 0" class="grid_container">
      <div v-for="(item, i) in itemsData" :key="i" class="grid_container__item">
        <div class="cell_container">
          <div class="cell_container__item select_terminal">
            <check-box @click="onSwitchValue" v-model:checked="item.selected" />
          </div>
          <div class="cell_container__item title">
            <label>{{ item.value }}</label>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="grid_container not_found">Не найдено</div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  components: {
    "check-box": defineAsyncComponent(() =>
      import("@/components/CheckBox.vue")
    ),
  },
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {
      itemsData: [],
    };
  },
  methods: {
    updateItemsData() {
      const temp = [];
      this.items.forEach((el) => temp.push({ value: el, selected: false }));
      this.itemsData = temp.sort((a, b) => {
        return a.value - b.value;
      });
    },
    onSwitchValue() {
      const temp = [];
      this.itemsData.forEach((el) => {
        if (el.selected) {
          temp.push(el.value);
        }
      });
      this.$emit("changeSelected", temp);
    },
    selectAll() {
      this.itemsData.forEach(el => el.selected = true)
      this.onSwitchValue()
    }
  },
  created() {
    this.updateItemsData();
  },
  watch: {
    items() {
      this.updateItemsData();
    },
  },
};
</script>

<style lang="less">
#grid_table {
    width: 100%;
  .grid_container {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    // max-height: calc(@row-number * calc(@cell-margin + @cell-height) + 20px);

    gap: 20px 5px;

    &.not_found {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .grid_container__item {
      height: 30px;
      justify-content: baseline;
      .cell_container {
        display: flex;
        align-items: center;
        width: 130px;
        height: 35px;
        background-color: #f1f6ff;
        border-radius: 5px;
        padding: 3px 15px;
        // margin: @cell-margin @cell-margin 0px 0px;
        .select_terminal {
          button {
            display: flex;
            align-items: center;
            margin-right: 10px;
          }
        }
        .title {
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }
}
</style>
